import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, unref as _unref, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import { computed } from 'vue';
import { useRouter } from 'vue-router';
import AuthLayout from './ui/AuthLayout.vue';
import { AuthForm, NewPasswordForm } from '@/v2/widgets';
import { ModalLayout, FooterLinks } from '@/v2/shared/ui';


export default /*@__PURE__*/_defineComponent({
  __name: 'Auth',
  setup(__props) {

const router = useRouter();
const pageComponents: Record<string, any> = {
  'cms.auth': AuthForm,
  'new-password': NewPasswordForm,
};

const currentComponent = computed(() => {
  return pageComponents[router.currentRoute.value.name as string];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AuthLayout, null, {
    footer: _withCtx(() => [
      _createVNode(_unref(FooterLinks))
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(ModalLayout), null, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(currentComponent.value), {
            password_input: true,
            code_to: 'Email',
            resend_time: 30,
            auth_type: 'Email',
            num_inputs: 6,
            support_email: 'admin@thefunctionalgutclinic.com'
          }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})